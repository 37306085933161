import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.mixin({
  //HELPERS
  methods: {
    /**
     * returns the local string of country the browser is opend
     * @param date
     * @returns {string} month in 2 digits, day in 2 digits and year numeric
     */
    getDateString: function(date) {
      let newDate = new Date(date);
      return newDate.toLocaleDateString('en-EN',
          { month: 'long', day: '2-digit', year: 'numeric'} );
    },
  }
})