import Vue from 'vue'
import Vuex from 'vuex'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '../config/oidc'

import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'
import data from './data.js'
//import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: data,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true
      }
    )
  }
  //plugins: [createPersistedState({ storage: window.sessionStorage })]
})
