import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-cli-plugin-vuetify-preset-bluesign/assets/_bs-font.scss'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { preset } from 'vue-cli-plugin-vuetify-preset-bluesign/preset'

Vue.use(Vuetify);

export default new Vuetify({
  preset,
  theme: {
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'md'||'mdi'||'bi',
  },
});
