<template>
    <v-container>
        <v-row flat class="pt-10">
            <v-col
                    v-for="item in serviceItems"
                    :key="item.title"
                    cols="12"
                    lg="3"
                    md="6"
                    class="py-2"
            >
                <service-cards :content="item"></service-cards>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-tabs v-model="tab"
                        color="secondary"
                        class="bs-tabs pt-10"
                >
                    <v-tab href="#tab-1" class="font-weight-bold text--secondary">
                        Announcements
                    </v-tab>

                    <v-tab href="#tab-2" class="font-weight-bold text--secondary">
                        System partner changes
                      <!--
                      <v-badge
                              color="red"
                              content="2"
                      >
                          Notifications
                      </v-badge>
                      -->
                    </v-tab>

                    <v-spacer/>
                    <!--
                      <v-btn color="negative" depressed>
                          <v-icon class="text--secondary pr-2">settings</v-icon>
                          <span class="mr-0 text-none text--secondary">Settings</span>
                      </v-btn>
                    -->
                </v-tabs>
                <v-tabs-items v-model="tab" class="bs-tabs-items">

                  <v-tab-item value="tab-1" class="mt-10 bs-tab-item">
                    <announcements/>
                  </v-tab-item>

                  <v-tab-item value="tab-2" class="mt-10 bs-tab-item">
                    <notifications/>
                  </v-tab-item>

                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ServiceCards from '../components/ServiceCards';
import Announcements from '../components/Announcements';
import Notifications from '../components/Notifications';
import {mapGetters} from 'vuex';

export default {
    data: () => ({
        tab: null,
    }),
    computed: {
        ...mapGetters({
            getUserRights: 'getUserRights',
            serviceItems: 'getServiceCards',
        }),
    },
    methods: {},
    components: {
        ServiceCards,
        Announcements,
        Notifications,
    },
};
</script>

<style lang="scss" scoped>
.bs-tabs-items {
    margin: 0 -16px !important;
    max-width: calc(100% + 32px);

    .bs-tab-item {
        margin: 0 16px;
    }
}
</style>
