<template>

  <v-container>

    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Debug Infos ({{env.environment}})
        </v-expansion-panel-header>
        <v-expansion-panel-content>


            <v-row>

              <v-col>
                <v-card>
                  <v-card-title>oidcAccessToken</v-card-title>
                  <v-card-text>{{oidcAccessToken}}</v-card-text>
                </v-card>
              </v-col>

            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>OIDC Identity</v-card-title>
                  <v-card-text>
                    oidcIsAuthenticated: {{oidcIsAuthenticated}}
                    <hr>
                    oidcUser: {{oidcUser}}
                    <hr>
                    oidcAccessTokenExp: {{oidcAccessTokenExp}}
                    <hr>
                    oidcIdToken: {{oidcIdToken}}
                    <hr>
                    oidcIdTokenExp: {{oidcIdTokenExp}}
                    <hr>
                    oidcRefreshToken: {{oidcRefreshToken}}
                    <hr>
                    oidcRefreshTokenExp: {{oidcRefreshTokenExp}}
                    <hr>
                    oidcAuthenticationIsChecked: {{oidcAuthenticationIsChecked}}
                    <hr>
                    oidcError: {{oidcError}}

                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>


            <v-row>

              <v-col>
                <v-card>
                  <v-card-title>User Rights</v-card-title>
                  <v-card-text>
                    {{getUserMe}}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>



        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>

</template>

<script>
import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'DebugInfos',
    components: {

    },
    data: () => ({
      env: {
        environment: process.env.VUE_APP_ENVIRONMENT,
      }
    }),
    computed: {
      ...mapGetters('oidcStore', [
        'oidcIsAuthenticated',
        'oidcUser',
        'oidcAccessToken',
        'oidcAccessTokenExp',
        'oidcIdToken',
        'oidcIdTokenExp',
        'oidcRefreshToken',
        'oidcRefreshTokenExp',
        'oidcAuthenticationIsChecked',
        'oidcError',
      ]),
      getUserMe () {
        return this.$store.getters.getUserMe
      }
    },
    methods: {
      ...mapActions('oidcStore', [
        'removeOidcUser',
          'signOutOidc'
      ]),
    },
    mounted: function () {

    },
    beforeDestroy () {

    }
  }
</script>
