
export default {
  token: window.sessionStorage.getItem('token') ? window.sessionStorage.getItem('token') : null,
  tokenSource: '',

  // START general bluesign Application data needed for the navigation, user dropdowns etc.

  // Loading spinner
  loadingState: false,

  // Snackbar
  snackbar: false,
  snackbarTimeout: 4000,
  snackbarColor: 'warning',
  currentSnackbarText: null,

  user: {
    rights: [],
    name: '',
    role: '',

    loginState: true,
    token: null,
    username: '',
    company: {
      id: null,
      name: ''
    },
  },

  mainMenuDrawer: false,
  filterDrawer: false,

  message: {
    title: '',
    content: ''
  },

  announcements: {},
  partnerChangeLogs: {},

  pagination: {
    announcements: {
      results: 5,
      page: 1
    },
    partnerChangeLogs: {
      results: 2,
      page: 1
    }

  },

  currentAlertText: null,

  // END general bluesign Application data needed for the navigation, user dropdowns etc.

  referenceData: {
    general: {
      companies: [],
    },
    company: {
      members: [
        {
          uid: 1,
          username: 'Phileas Fogg',
          jobTitle: 'CEO'
        },
        {
          uid: 2,
          username: 'Arthur Dent',
          jobTitle: 'Product Manager'
        },
        {
          uid: 3,
          username: 'John Smith',
          jobTitle: 'Product Manager'
        }
      ],
    }
  }

}
