<template>
  <div class="row">
    <div class="col-12">
      <v-expansion-panels>
        <v-expansion-panel
            class="mb-6 elevation-6"
            v-for="announcement in announcements"
            :key="announcement.id"
        >
          <v-expansion-panel-header class="text--primary" expand-icon="mdi-chevron-down">
            <span class="text--secondary">{{announcement.title}}</span>
            <v-spacer/>
            <span class="text-end pr-5">{{ getDateString(announcement.created) }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="text--primary">
            <span class="bs-newline">{{ announcement.description }}</span>
            <span v-if="announcement.link">
                <a target="_self" :href="announcement.link.link">{{ announcement.link.text }}</a>
              </span>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <div class="row">
          <v-spacer/>

          <div class="col-auto" v-if="pagination">
            <span class="bs-font-size--0-8x text--primary pr-5">Page {{ pagination.currentPage }} of {{ pagination.total }}</span>
            <v-btn depressed v-if="pagination.currentPage > 1" @click="prevStep">
              <v-icon color="primary">mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn depressed v-if="pagination.currentPage < pagination.total " @click="nextStep">
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: 'Announcements',
    data: () => ({
      //
    }),
    computed: {
      ...mapState({
        announcements: state => state.announcements.values,
        pagination: state => state.announcements.paginatorInfo,
        loading: state => state.loadingState,
      }),
      ...mapGetters('oidcStore', ['oidcAccessToken'])
    },
    methods: {
      nextStep: function () {
        let token = this.oidcAccessToken
        this.$store.commit('paginationPageIncrementAnnouncements')
        this.$store.dispatch('readAnnouncements', token)
      },
      prevStep: function () {
        let token = this.oidcAccessToken
        this.$store.commit('paginationPageDecrementAnnouncements')
        this.$store.dispatch('readAnnouncements', token);
      }
    }
  }
</script>

<style scoped>
</style>
