<template>
  <v-navigation-drawer
      v-model="mainMenuDrawer"
      app
      temporary
      overlay-color="secondary"
      overlay-opacity="0.1"
      width="400px">
    <v-container>
      <v-row justify="space-between">
        <v-col>
          <h2 class="display-1">{{ user.company.name }}</h2>
          <h3 class="title">{{ user.name }}</h3>
        </v-col>
        <v-col cols="2">
          <v-btn icon
                 v-on:click="mainMenuDrawer = !mainMenuDrawer" retain-focus-on-click>
            <v-icon class="bs-font-size--2x" color="secondary">close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-list tile
            flat
            dense>
      <v-divider></v-divider>
      <template v-for="(item, index) in menuItems">
        <v-subheader v-if="item.header"
                     :key="index"
                     v-text="item.header">

        </v-subheader>
        <v-divider v-else-if="item.divider"
                   :key="index"></v-divider>
        <v-list-item
            v-else
            link
            color="secondary"
            :href="item.path"
            :key="index"
            :disabled="!item.role"
            @click="expandLinks(index)"
        >
          <v-list-item-icon>
            <v-icon class="bs-font-size--3x" :color="item.iconColor">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content two-line>
            <v-list-item-title :class="{'text--secondary':item.role, 'text--primary': !item.role}"><strong>{{
                item.title
              }}</strong></v-list-item-title>
            <v-list-item-subtitle :class="{'text--secondary':item.role, 'text--primary': !item.role}">{{
                item.subtitle
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="item.links && item.links.length >0 && !item.isExpanded && item.role">
            <v-btn icon>
              <v-icon>keyboard_arrow_down</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-if="item.links && item.links.length >0 && item.isExpanded && item.role">
            <v-btn icon>
              <v-icon>keyboard_arrow_up</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="item.subtitle" v-if="item.isExpanded"></v-divider>
        <v-list-item :key="item.title" v-if="item.isExpanded">
          <v-list-item-icon></v-list-item-icon>
          <v-list
              class="py-1"
              dense
          >
            <v-list-item
                v-for="(item, index) in item.links"
                v-show="item.role"
                :key="index"
                :href="item.path"
                link
                :disabled="!item.role"
                target="_self"
            >
              <v-list-item-content class="px-2">
                <v-list-item-title :class="{'text--primary': !item.role}">{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-item>
      </template>
      <v-list-item
          link
          color="secondary"
          @click="logout"
          v-if="user.loginState"
      >
        <v-list-item-icon>
          <v-icon class="bs-font-size--3x" color="primary">power_settings_new</v-icon>
        </v-list-item-icon>

        <v-list-item-content two-line>
          <v-list-item-title class="text--secondary"><strong>LOGOUT</strong></v-list-item-title>
          <v-list-item-subtitle>Closes your session</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          link
          color="secondary"
          @click="login"
          v-if="!user.loginState"
      >
        <v-list-item-icon>
          <v-icon class="bs-font-size--3x" color="primary">power_settings_new</v-icon>
        </v-list-item-icon>

        <v-list-item-content two-line>
          <v-list-item-title class="text--secondary"><strong>LOGIN</strong></v-list-item-title>
          <v-list-item-subtitle>Enter new session</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import {mapFields} from 'vuex-map-fields';
import {mapState, mapActions, mapGetters} from 'vuex';

export default {
  name: 'MainMenuDrawer',
  data: () => ({
    company: 'Texcomp AG',
    menuItems: []
  }),
  mounted() {
    this.createCards()
  },
  computed: {
    ...mapFields({
      mainMenuDrawer: 'mainMenuDrawer',
    }),
    ...mapState({
      tools: state => state.referenceData.tools,
    }),
    ...mapGetters({
      getServiceCards: 'getServiceCards',
      user: 'getUserMe',
    })
  },
  watch: {
    'getServiceCards': function () {
      this.createCards()
    }
  },
  methods: {
    ...mapActions('oidcStore', [
      'removeOidcUser',
      'signOutOidc',
    ]),

    login: function () {
      this.$store.dispatch('userLogin');
      this.$router.push({path: 'products'});
      this.$emit('menuToggle');
    },
    logout: function () {
      this.signOutOidc().then(() => {
        this.$emit('menuToggle');
        //this.$router.push('/')
      });
    },
    expandLinks(index){
      if (this.menuItems[index].links.length >0 && !this.menuItems[index].isExpanded){
        this.menuItems[index].isExpanded = true;
      } else {
        this.menuItems[index].isExpanded = false;
      }
    },
    createCards() {
      let cards = [];
      this.getServiceCards.forEach(item => {
        let newCard = item;
        newCard.isExpanded = false
        let divider = {divider: true};
        cards.push(newCard);
        cards.push(divider);
      });
      this.menuItems = JSON.parse(JSON.stringify(cards))
    },
    getCompanyById: function (id) {
      return this.$store.getters.getCompanyById(id).name;
    },
  },
  props: [],
};
</script>
