<template>
    <v-card flat width="280px">
        <v-menu :close-on-content-click="closeOnClick"
                offset-y
                :attach="'#userdropdown'">
            <template v-slot:activator="{ on, attrs }">
                <v-card flat id="userdropdown"
                        v-bind="attrs"
                        width="100%"
                        class="d-flex justify-end bs-no-bg--hover"
                        v-on="on">
                    <v-btn
                            v-if="getUserMe.name"
                            text
                            :ripple="false"
                            class="font-weight-bold text-none text--secondary bs-no-bg--hover"
                    >
                        {{ getUserMe.name }}
                        <v-icon class="ml-2">mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-btn v-else
                           icon
                           :ripple="false"
                           class="font-weight-bold text-none text--secondary">
                        <v-icon class="ml-2">mdi-account</v-icon>
                    </v-btn>
                </v-card>
            </template>
            <v-list dense width="280px" class="py-0">
                <v-list-item v-if="getUserMe.loginState" link>
                    <v-list-item-icon>
                        <v-icon class="text--primary">mdi-check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text--secondary">{{ getUserMe.company.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-subheader v-if="getUserMe.loginState && links.length > 0" class="text--bsFontColor mt-3" inset>Quick
                    links
                </v-subheader>
                <v-list-item-group v-if="getUserMe.loginState" v-model="link" color="secondary">
                    <v-list-item
                            v-for="(link, i) in links"
                            :key="i"
                            :href="link.path"
                    >
                        <v-list-item-icon>
                            <v-icon class="text--primary">{{ link.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text--secondary">{{ link.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                <v-list-item-group color="secondary">
                    <v-list-item v-if="getUserMe.loginState" @click="logout">
                        <v-list-item-icon>
                            <v-icon class="text--primary">mdi-power</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text--secondary">Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="!getUserMe.loginState"
                                 href="/">
                        <v-list-item-icon>
                            <v-icon class="text--primary">mdi-power</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text--secondary">Login</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'UserDropdown',
    data: () => ({
        rippleEffect: false,
        closeOnClick: true,
        item: 0,
        link: null,
        links: [],
    }),
    computed: {
        ...mapGetters(['getUserMe']),
    },
    methods: {
        ...mapActions('oidcStore', [
            'removeOidcUser',
            'signOutOidc',
        ]),
        logout: function() {
            this.signOutOidc().then(() => {
                this.$emit('menuToggle');
                //this.$router.push('/')
            });
        },
        getCompanyById: function(id) {
            return this.$store.getters.getCompanyById(id).name;
        },
    },
};
</script>

<style scoped>
.bs-no-bg--hover:hover:before, .bs-no-bg--hover:focus:before{
    background-color: transparent;
}
</style>
