<template>
  <v-container>
    <v-row class="mt-12" justify="space-between" align="center">
      <v-col cols="12" sm="auto" class="align-center justify-center justify-sm-start d-flex">
        <v-btn icon tile v-on:click="toggleMainMenu" :ripple="false">
          <v-icon class="bs-font-size--3x text--secondary">mdi-menu</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="auto" align="center">
        <span class="text-none display-1">
            {{ getUserMe.company.name }}
        </span>
      </v-col>
      <v-spacer/>
      <v-col cols="auto" class="pa-0">
        <user-dropdown></user-dropdown>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapFields} from 'vuex-map-fields';
import UserDropdown from '@/components/UserDropdown'
import { mapGetters } from 'vuex'

export default {
  name: 'InAppNavigation',
  components: { UserDropdown },
  data: () => ({
  }),
  computed: {
    ...mapFields({
      mainMenuDrawer: 'mainMenuDrawer',
    }),
    ...mapGetters(['getUserMe']),
  },
  methods: {
    back: function () {
      this.$router.back()
    },
    toggleMainMenu: function() {
      this.mainMenuDrawer = !this.mainMenuDrawer;
    },
  }
}
</script>
