import axios from "axios";

export default {
  readUserPermissions({commit}, payload) {
    commit('updateLoadingState');
    const headers = { Authorization: 'Bearer ' + payload }
    const query = `{
        authorize {
          name
          role
          rights
          company {
            id
            name
          }
        }
      }`
    axios({
      url: process.env.VUE_APP_GRAPHQL,
      method: 'post',
      withCredentials: true,
      headers: headers,
      data: {
        query: query
      }
    }).then(response => {
      //console.log(response)
      // check if there is any error in the response
      if(response.data.errors && response.data.errors.length>0) {
        let error = `There was an error while loading the rights. Error message: ${response.data.errors[0].message}`
        commit('setSnackbarMessage', {note: error, color: 'warning'});
      } else {
        commit('updateUserFromAuthorization', response.data.data.authorize)
      }
      commit('updateLoadingState')
    }).catch(err => {
      //console.log(err)
      commit('updateLoadingState')
      commit('setSnackbarMessage', {note: err, color: 'warning'});
    })
  },
  readAnnouncements({commit, state}, payload) {
    commit('updateLoadingState');
    const headers = { Authorization: 'Bearer ' + payload }
    const query = `
      {
        announcements(
        page: ${state.pagination.announcements.page}, 
        first: ${state.pagination.announcements.results}
        )
        {
          first
          page
          values {
            id
            title
            description
            created
            link {
              link
              text
            }
        }
        paginatorInfo 
        {
          currentPage
          lastPage
          total
        }
      }
    }`
    axios({
      url: process.env.VUE_APP_GRAPHQL,
      method: 'post',
      withCredentials: true,
      headers: headers,
      data: {
        query: query
      }
    }).then(response => {
      //console.log(response)
      // check if there is any error in the response
      if(response.data.errors && response.data.errors.length>0) {
        let error = `There was an error while loading the rights. Error message: ${response.data.errors[0].message}`
        commit('setSnackbarMessage', {note: error, color: 'warning'});
      } else {
        commit('setAnnouncements', response.data.data.announcements)
      }
      commit('updateLoadingState');
    }).catch(err => {
      //console.log(err)
      commit('updateLoadingState');
      commit('setSnackbarMessage', {note: err, color: 'warning'});
    })
  },
  readPartnerChangeLogs({commit, state}, payload) {
    commit('updateLoadingState');
    const headers = { Authorization: 'Bearer ' + payload }
    const query = `
      {
        partnerChangeLogs(
        page: ${state.pagination.partnerChangeLogs.page}, 
        first: ${state.pagination.partnerChangeLogs.results}
        ) 
        {
          first
          page
          values {
            id
            title
            description
            created
        }
        paginatorInfo 
        {
          currentPage
          lastPage
          total
        }
      }
    }`
    axios({
      url: process.env.VUE_APP_GRAPHQL,
      method: 'post',
      withCredentials: true,
      headers: headers,
      data: {
        query: query
      }
    }).then(response => {
     // console.log(response)
      // check if there is any error in the response
      if(response.data.errors && response.data.errors.length>0) {
        let error = `There was an error while loading the rights. Error message: ${response.data.errors[0].message}`
        commit('setSnackbarMessage', {note: error, color: 'warning'});
      } else {
        commit('setPartnerChangeLogs', response.data.data.partnerChangeLogs)
      }
      commit('updateLoadingState');
    }).catch(err => {
      //console.log(err)
      commit('updateLoadingState');
      commit('setSnackbarMessage', {note: err, color: 'warning'});
    })
  },
}
