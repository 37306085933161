import {getField} from 'vuex-map-fields';

export default {
  getField,
  getUserMe: state => state.user,

  // Token (all deprecated)
  hasToken: state => !!state.token,
  getToken: state => state.token,
  getTokenSource: state => state.tokenSource,
  getTokenData: (state, getters) => state.token ? JSON.parse(
      atob(getters.getToken.split('.')[1])) : null,
  getTokenRoles: (state, getters) => getters.getTokenData
      ? getters.getTokenData.Role
      : null,
  getTokenRights: (state, getters) => getters.getTokenData
      ? getters.getTokenData.Rights
      : null,
  getUserRights: (state) => state.user.rights ? state.user.rights : [],

  // roleIsSysAdmin: (state, getters) => getters.getTokenData ? getters.getTokenRoles.includes('SYSADMIN') : false,
  roleIsDev: (state, getters) => getters.getTokenData
      ? getters.getTokenRoles.includes('is-dev')
      : false,
  roleIsAdmin: () => true,

  // User roles
  roleCanSystem: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('SYSTEMPARTNER_ACCESS')
      : false,
  roleCanFinder: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('FINDER_ACCESS')
      : false,
  roleCanGuide: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('GUIDE_ACCESS')
      : false,
  roleCanGuideOld: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('GUIDE_OLD_ACCESS')
      : false,
  roleCanDownload: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('DOCDOWNLOAD_ACCESS')
      : false,
  roleCanAssessment: (state, getters) => getters.getUserRights
      // Todo: Make it better -> temporary conditions until response assessment delivered
      ? getters.getUserRights.includes('ASSESSMENT_ACCESS') && getters.getUserRights.includes('ASSESSMENT_ALW_CILCHANGES')
      || getters.getUserRights.includes('ASSESSMENT_ACCESS') && getters.getUserRights.includes('ASSESSMENT_ALW_HOMOLOGATION_OTHER')
      || getters.getUserRights.includes('ASSESSMENT_ACCESS') && getters.getUserRights.includes('ASSESSMENT_ALW_CILCHANGES') && getters.getUserRights.includes('ASSESSMENT_ALW_HOMOLOGATION_OTHER')
      : false,
  roleCanAssessmentChemicalInventory: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('ASSESSMENT_ALW_CILCHANGES') || getters.getUserRights.includes('ASSESSMENT_ALW_CILCHANGES')
      : false,
  roleCanAssessmentHomologation: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('ASSESSMENT_ALW_HOMOLOGATION') || getters.getUserRights.includes('ASSESSMENT_ALW_HOMOLOGATION_OTHER')
      : false,
  roleCanTool: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('TOOL_ACCESS')
      : false,
  roleCanXpert: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('EXPERT_ACCESS')
      : false,
  roleCanToolOld: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('TOOL_OLD_ACCESS')
      : false,
  roleCanNavigator: (state, getters) => getters.getUserRights
      ? getters.getUserRights.includes('NAVI_ACCESS')
      : false,

  //Companies
  getCompanyById: (state) => (id) => {
    let result = state.referenceData.general.companies.find(
        company => company.id == id);
    if (result) {
      return result;
    } else {
      return [];
    }
  },

  getServiceCards: (state, getters) => {
    return [
      /*
      {
        icon: 'mdi-arrow-left',
        iconColor: 'primary',
        title: 'HOME',
        subtitle: 'Back to initial landing page',
        iconClass: 'bs-font-size--4x',
        role:true,
        path: '/',
      },*/
      {
        icon: 'bi-system-inv',
        iconColor: getters.roleCanSystem ? 'secondary' : 'primary',
        title: 'SYSTEM',
        subtitle: 'Collaboration and network',

        iconClass: 'bs-font-size--4x',
        role: getters.roleCanSystem,
        links: [
          {
            name: 'Partner Search',
            path: process.env.VUE_APP_URL_SYSTEM_PARTNER_SEARCH,
            role: getters.roleCanSystem,
          },
          {
            name: 'My Dashboard',
            path: process.env.VUE_APP_URL_SYSTEM_MY_DASHBOARD,
            role: getters.roleCanSystem,
          },
          {
            name: 'My Company',
            path: process.env.VUE_APP_URL_SYSTEM_MY_COMPANY,
            role: getters.roleCanSystem,
          },
        ],
      },
      {
        icon: 'bi-bluefinder-inv',
        iconColor: getters.roleCanFinder ? 'secondary' : 'primary',
        title: 'FINDER',
        subtitle: 'Positive chemicals list',
        path: process.env.VUE_APP_URL_FINDER,
        iconClass: 'bs-font-size--4x',
        role: getters.roleCanFinder,
        links: '',
      },
      {
        icon: 'bi-blueguide-inv',
        iconColor: getters.roleCanGuide ? 'secondary' : 'primary',
        title: 'GUIDE',
        subtitle: 'Approved components',
        path: process.env.VUE_APP_URL_GUIDE,
        iconClass: 'bs-font-size--4x',
        role: getters.roleCanGuide,
        links: [
          {
            name: 'New Version',
            path: process.env.VUE_APP_URL_GUIDENEW,
            role: getters.roleCanGuide,
          },
          {
            name: 'Old Version',
            path: process.env.VUE_APP_URL_GUIDEOLD,
            role: getters.roleCanGuideOld,
          },
        ],
      },
      {
        icon: 'bi-download-inv',
        iconColor: getters.roleCanDownload ? 'secondary' : 'primary',
        title: 'DOWNLOAD',
        subtitle: 'Shared knowledge',
        path: process.env.VUE_APP_URL_DOWNLOAD,
        iconClass: 'toolbar-icon ma-2',
        role: getters.roleCanDownload,
        links: '',
      },
      {
        icon: 'bi-assessment-inv',
        iconColor: getters.roleCanAssessment ? 'secondary' : 'primary',
        title: 'ASSESSMENT',
        subtitle: 'Workbench',
        iconClass: 'bs-font-size--4x',
        role: getters.roleCanAssessment,

        links: [
          {
            name: 'Chemical Inventory',
            path: process.env.VUE_APP_URL_ASSESSMENT_CHEMICAL_INVENTORY,
            role: getters.roleCanAssessmentChemicalInventory,
          },
          {
            name: 'Homologation',
            path: process.env.VUE_APP_URL_ASSESSMENT_HOMOLOGATION,
            role: getters.roleCanAssessmentHomologation,
          },
        ],
      },
      {
        icon: 'bi-bluetool-inv',
        iconColor: getters.roleCanTool ? 'secondary' : 'primary',
        title: 'TOOL',
        subtitle: 'Chemicals assessment tool',
        iconClass: 'bs-font-size--4x',
        role: getters.roleCanTool,
        links: [
          {
            name: 'New Version',
            path: process.env.VUE_APP_URL_TOOLNEW,
            role: getters.roleCanTool,
          },
          {
            name: 'Old Version',
            path: process.env.VUE_APP_URL_TOOLOLD,
            role: getters.roleCanToolOld,
          },
        ],
      },
      {
        icon: 'bi-bluexpert-inv',
        iconColor: getters.roleCanXpert ? 'secondary' : 'primary',
        title: 'XPERT',
        subtitle: 'Resource productivity calculator',
        path: process.env.VUE_APP_URL_XPERT,
        iconClass: 'bs-font-size--4x',
        role: getters.roleCanXpert,
        links: '',
      },
      {
        icon: 'bi-bluehsdb-inv',
        iconColor: getters.roleCanNavigator ? 'secondary' : 'primary',
        title: 'SUBSTANCE NAVIGATOR',
        subtitle: 'Substances management center',
        path: process.env.VUE_APP_URL_NAVIGATOR,
        iconClass: 'bs-font-size--4x',
        role: getters.roleCanNavigator,
        links: '',
      },
    ];
  },
};
