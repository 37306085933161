const base = process.env.VUE_APP_BASE_URL

export const oidcSettings = {
  authority: process.env.VUE_APP_IDENTITY,
  clientId: 'cube-landing',
  redirectUri: base + 'callback',
  popupRedirectUri: base + 'oidc-popup-callback',
  responseType: 'code',
  scope: 'openid profile api1 roles',
  loadUserInfo: true,
  filterProtocolClaims: true,
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  silentRedirectUri: base + 'silent-renew-oidc.html',
  postLogoutRedirectUri: process.env.VUE_APP_IDENTITY_POST_LOGOUT_URI
}
