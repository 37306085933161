<template>
    <v-app class="overflow-hidden">
        <v-snackbar top
                    :timeout="snackbarTimeout"
                    v-model="snackbar"
                    :color="snackbarColor">
          <span class="font-weight-bold" v-if="snackbarColor === 'warning'">{{ snackbarText }}</span>
          <span class="font-weight-bold text--secondary" v-else>{{ snackbarText }}</span>
        </v-snackbar>
        <v-app-bar app
                   color="disabledPrim"
                   absolute
                   shrink-on-scroll
                   scroll-target="#scrollTech"
                   flat
                   prominent
                   class="bs-toolbar--shrinkable">
            <v-toolbar-items class="align-end pb-2">
                <v-icon color="secondary" class="toolbar-icon">bi-systempartner-inv</v-icon>
                <v-divider
                        class="pa-2"
                        inset
                        vertical>
                </v-divider>
            </v-toolbar-items>
            <v-toolbar-title class="pl-4 pb-0">
                <h1 class="display-4"><span class="text--secondary">System partner area</span>
                    <span class="hidden-xs-only text--primary"> – Responsible textile business in the 3rd dimension.</span>
                </h1>
            </v-toolbar-title>
            <v-spacer/>
            <v-toolbar-items>
                <v-img
                        contain
                        max-width="96px"
                        min-width="56px"
                        src="@/assets/logo.png"
                        class="mb-2 cursor--pointer"
                        @click.stop="toggleMainMenu"/>
            </v-toolbar-items>
        </v-app-bar>
        <main-menu-drawer :mainMenuDrawer="mainMenuDrawer"/>
        <v-main>
            <v-sheet id="scrollTech"
                     class="overflow-y-auto"
                     max-height="calc(100vh - 216px)">
                <v-container>
                    <v-row>
                        <v-col cols="12" class="pt-0">
                            <in-app-navigation v-if="oidcIsAuthenticated" v-on:menuToggle="toggleMainMenu"/>
                            <router-view/>
                            <v-overlay :value="loading"
                                       opacity="0.1"
                                       color="secondary"
                                       z-index="1000">
                                <v-progress-circular
                                    :size="115"
                                    :width="7"
                                    color="secondary"
                                    indeterminate
                                    v-if="loading"
                                ><br>
                                    <span class="font-weight-bold black--text">Loading ...</span>
                                </v-progress-circular>
                            </v-overlay>
                        </v-col>
                        <v-col cols="12">
                            <debug-infos  v-if="env.env === 'Development'"></debug-infos>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
        </v-main>
        <v-divider/>
        <v-footer
                inset
                min-height="80px">
            <v-container class="bs-container">
                <v-row justify="space-between" align="center">
                    <v-col>
                        <span>© 2020 bluesign technologies ag - Switzerland</span>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </v-app>
</template>

<script>
import MainMenuDrawer from './components/MainMenuDrawer';
import InAppNavigation from './components/InAppNavigation';
import DebugInfos from './components/DebugInfos';

import {mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import {mapState} from 'vuex';

export default {
    name: 'App',
    components: {
        MainMenuDrawer,
        InAppNavigation,
        DebugInfos,

    },
    data: () => ({
        env: {
            alert: false,
            env: process.env.VUE_APP_ENVIRONMENT,
        },
    }),
    computed: {
        ...mapState({
            snackbarTimeout: state => state.snackbarTimeout,
            snackbarText: state => state.currentSnackbarText,
            snackbarColor: state => state.snackbarColor,
            loading: state => state.loadingState,
        }),
        ...mapGetters('oidcStore', [
            'oidcIsAuthenticated',
        ]),
        ...mapFields({
            mainMenuDrawer: 'mainMenuDrawer',
            filterDrawer: 'filterDrawer',
            snackbar: 'snackbar',
        }),
    },
    methods: {
        toggleMainMenu: function() {
            this.mainMenuDrawer = !this.mainMenuDrawer;
        },
        userLoaded: function(e) {
            let token = e.detail.access_token
            this.$store.dispatch('readUserPermissions', token).then(()=> {
                this.$store.dispatch('readAnnouncements', token).then(()=> {
                    this.$store.dispatch('readPartnerChangeLogs', token);
                });
            });
        },
    },
    created: function() {
        window.addEventListener('vuexoidc:userLoaded', this.userLoaded);
    },
    destroyed() {
        window.removeEventListener('vuexoidc:userLoaded');
    },
};
</script>
<style lang="scss">

.bs-toolbar--shrinkable {
    &.theme--light.v-sheet {
        border-bottom: 1px solid $primary !important;
    }

    & > .v-toolbar__content {
        max-width: 100%;
        margin: 0 auto;
        @media all and (min-width: 960px) {
            max-width: 960px;
        }
        @media all and (min-width: 1264px) {
            max-width: 1264px;
        }
    }


}
</style>
