import {updateField} from 'vuex-map-fields';

export default {
  updateField,

  updateUserFromAuthorization(state, payload) {
    state.user.rights = payload.rights
    state.user.role = payload.role
    state.user.name = payload.name
    state.user.company.id = payload.company.id
    state.user.company.name = payload.company.name
  },

  updateLoadingState(state) {
    state.loadingState = !state.loadingState;
  },

  setFilterDrawer(state, payload) {
    state.filterDrawer = payload;
  },

  setAnnouncements(state, payload) {
    state.announcements = payload
  },

  setPartnerChangeLogs(state, payload) {
    state.partnerChangeLogs = payload
  },

  toggleMainMenu(state, payload) {
    state.mainMenuDrawer = payload;
  },
  setSnackbarMessage(state, payload) {
    state.currentSnackbarText = payload.note ? payload.note : payload;
    state.snackbarColor = payload.color ? payload.color : 'warning';
    state.snackbar = true;
  },
  paginationPageIncrementAnnouncements(state) {
    state.pagination.announcements.page++;
  },
  paginationPageDecrementAnnouncements(state) {
    state.pagination.announcements.page--;
  },
  paginationPageIncrementNotifications(state) {
    state.pagination.announcements.page++;
  },
  paginationPageDecrementNotifications(state) {
    state.pagination.announcements.page--;
  },
};
