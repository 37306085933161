<template>
    <v-card flat>
        <v-menu :attach="'#'+content.title" offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-card raised
                        width="100%"
                        class="d-inline-flex elevation-6"
                        :href="content.path"
                        :disabled="!content.role"
                        :id="content.title"
                        v-bind="attrs"
                        v-on="on"
                >
                    <v-icon :class="content.iconClass" :color="content.iconColor">{{ content.icon }}</v-icon>
                    <v-card-text class="pt-1 px-0 pb-0">
                        <v-card-title class="text--primary py-2 px-3" :class="{'text--secondary':content.role}"><h3
                                class="text--smaller">{{ content.title }}</h3></v-card-title>
                        <v-card-subtitle class="text--primary py-0 px-3"><h5>{{ content.subtitle }}</h5>
                        </v-card-subtitle>
                    </v-card-text>
                </v-card>
            </template>
            <v-list
                    v-for="(item, index) in links"
                    :key="index"
                    class="py-1"
            >
                <v-list-item
                        :href="item.path"
                        v-if="item.role"
                        link
                        target="_self"
                >
                    <v-list-item-title class="text--secondary">{{ item.name }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-card>
</template>

<script>

export default {
    name: 'ServiceCard',
    data: () => ({
        env: {
            environment: process.env.VUE_APP_ENVIRONMENT,
            toolUrl: process.env.VUE_APP_URL_TOOL,
        },
    }),
    computed:{
        links(){
            if (this.content.links){
            return this.content.links.filter(link => link.role === true)
            }
            else {
                return []
            }
        }
    },
    props: [
        'content',
    ],
};
</script>

<style scoped lang="scss">
.bs-font-size {
    &--4x {
        font-size: 4rem !important;
    }
}

.text--smaller {
    font-size: 1.1rem !important;
}
</style>
